import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from '../MessageBox_2.module.scss';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

TimeAgo.addLocale(en)

const Notes = (props) => {
    const { notes} = useSelector(
        (state) => ({
            notes: state.notes,
        }), shallowEqual
    );
        // console.log(notes);
    return (
            <ul className={styles.note_1}>
                {notes.callcenter && notes.callcenter.length ? notes.callcenter.map((post, index) => {
                let m = new Date(post.time.seconds*1000);
                return <li key={index}>
                    <div>
                    <p>{post.note}</p>
                    <small>{m.toLocaleString("en-US", {timeZone: "Europe/Berlin"})}</small>
                    </div>
                </li>
                }) : ""}
            </ul>)
}
export default Notes;
