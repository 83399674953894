import { createReducer } from 'redux-act';

import {
    STATS
} from '../actions/stats';

const initialState = {};

const stat = createReducer(
    {
        [STATS]: (state, payload) => ({
            ...state,
            ...payload
        })
    },
    initialState
);
export default stat