import { createAction } from 'redux-act';
import firebase from '../../firebase.js';
import firebase_app from 'firebase/compat/app';
import { error } from './dashboard'
import { onChange } from './form'
import { fakeData, userGetData } from './users'
import { sendEmail } from './auth'
import { defaultFakeId } from "../../Settings"
import { firebaseError } from "../../Utils"


export const MESSAGES_FAIL = createAction('MESSAGES_FAIL');
export const CLEAR_NOTES = createAction('CLEAR_NOTES');
export const CLEAR_USERS = createAction('CLEAR_USERS');
export const CLEAR_MESSAGES = createAction('CLEAR_MESSAGES');
export const MESSAGES_CLOSE = createAction('MESSAGES_CLOSE');
export const ACTIVE_MESSAGES_DATA = createAction('ACTIVE_MESSAGES_DATA');
export const MESSAGES_DATA_RESET = createAction('MESSAGES_DATA_RESET');
export const MESSAGES_DATA_RESET_2 = createAction('MESSAGES_DATA_RESET_2');
export const IMAGE_UPLOAD_START = createAction('IMAGE_UPLOAD_START');
export const IMAGE_UPLOAD_END = createAction('IMAGE_UPLOAD_END');
export const MESSAGE_IMAGE_DELETE = createAction('MESSAGE_IMAGE_DELETE');
export const MESSAGE_CLEAN_ERROR = createAction('MESSAGE_CLEAN_ERROR');
export const MESSAGE_CLEAR = createAction('MESSAGE_CLEAR');
export const MESSAGE_STATUS = createAction('MESSAGE_STATUS');
export const ACTIVE_MESSAGES_LIST_DATA = createAction('ACTIVE_MESSAGES_LIST_DATA');
export const ACTIVE_MESSAGES_IMAGES_DATA = createAction('ACTIVE_MESSAGES_IMAGES_DATA');
let unsubscribe2;
let unsubscribe;

const messagesReset = () => {
    return (dispatch) => {
        dispatch(MESSAGES_DATA_RESET_2())
    }
}

const setMessageOpen = (status, checkTwo) => {
    return (dispatch, getState) => {
        const { docId } = getState().auth.userData;
        const { activeMessage } = getState().form;
        const messageList = getState().messagesList.list ? getState().messagesList.list : [];
        if (window.innerWidth > 991) {
            if (!activeMessage && messageList[0] && status === true) {
                let fakeId = messageList[0].to[0]
                if (fakeId === docId) {
                    fakeId = messageList[0].to[1]
                }
                if (!checkTwo) {
                    dispatch(openMessageCheck(fakeId, true))
                }
            }
        }
        if (status === 2 && !activeMessage) {
            return null
        }
        if (status === false && activeMessage) {
            dispatch(onChange("activeMessage", ""))
        }
        dispatch(MESSAGE_STATUS(status))
    }
}

const getMessages = (first) => {
    return async (dispatch, getState) => {
        const userData = getState().auth.userData;
        dispatch(fakeData({}))
        dispatch(userGetData({}))
        dispatch(ACTIVE_MESSAGES_LIST_DATA({}))
        if (unsubscribe2) {
            unsubscribe2();
        }
        unsubscribe2 = firebase.firestore().collection("messagesList").where("callcenterId", "==", userData.docId).limit(1).onSnapshot(doc => {
            let arr = [];
            let run = false;
            doc.forEach(doc => {
                const { activeMessage } = getState().form;
                // console.log(activeMessage);
                // console.log(doc.id);
                // console.log(first);
                if ((activeMessage !== doc.id) || first) {
                    first = false;
                    run = true
                    let data = doc.data();
                    data["docId"] = doc.id
                    arr = data;
                    dispatch(openMessage(data, true))
                }
            })
            if (run) {
                dispatch(ACTIVE_MESSAGES_DATA(arr))
            }
        })
    }
}
const openMessage2 = (messageList, first) => {
    return async (dispatch, getState) => {
        const userData = getState().auth.userData;
        firebase.firestore().collection("messagesList").doc(messageList.docId).update({ callcenterId: userData.docId })
    }
}
const openMessage = (messageList, first) => {
    return async (dispatch, getState) => {
        const userData = getState().auth.userData;
        dispatch(MESSAGES_DATA_RESET())
        dispatch(onChange("message", ""))
        dispatch(onChange("activeMessage", messageList.docId))
        var activeclass = document.querySelectorAll('#messageList li');
        for (var i = 0; i < activeclass.length; i++) {
            activeclass[i].classList.remove('active');
        }
        if (document.getElementById(messageList.docId)) {
            document.getElementById(messageList.docId).classList.add('active')
        }
        // if(!first){

        if (messageList.oldType) {
            firebase.firestore().collection("messagesList").doc(messageList.docId).update({ callcenterId: userData.docId, callcenterList: userData.docId, type: messageList.oldType, oldType: "" })
        } else {
            firebase.firestore().collection("messagesList").doc(messageList.docId).update({ callcenterId: userData.docId, callcenterList: userData.docId })
        }
        // }
        if (unsubscribe) {
            unsubscribe();
        }
        firebase.firestore().collection("callcenters").doc(userData.docId).update({ activeMessageId: messageList.docId })


        unsubscribe = firebase.firestore().collection("messages").where("id", "==", messageList.docId).orderBy("time", "desc").limit(100).onSnapshot(list => {
            const form = getState().form;
            if (list.docs[0] && list.docs[0].data()) {
                if (form.activeMessage !== list.docs[0].data().id) {
                    unsubscribe()
                    return null
                }
            }
            let messages = []
            list.forEach(doc => {
                messages.unshift({
                    i: doc.id,
                    listId: list.id,
                    time: doc.data().time,
                    type: doc.data().type,
                    post: doc.data().post,
                    buy: doc.data().buy,
                    cc: doc.data().cc,
                    image: doc.data().image,
                    message: doc.data().message,
                    visible: doc.data().visible,
                })
                if (!doc.data().cc && doc.data().get.split("_")[0] === defaultFakeId) {
                    firebase.firestore().collection("messages").doc(doc.id).update({
                        cc: userData.docId,
                        visible: true,
                    })
                }
            })
            dispatch(ACTIVE_MESSAGES_LIST_DATA(messages))
        })
        firebase.firestore().collection("messages").where("id", "==", messageList.docId).where("type", "==", "photo").get().then(doc => {
            let arr = []
            doc.forEach(doc => {
                arr.push(doc.data().image)
            })
            dispatch(ACTIVE_MESSAGES_IMAGES_DATA(arr))
        })
        firebase.firestore().collection("users").doc(messageList.to[0]).get().then(doc => {
            if (doc.data()) {
                if (doc.data().disable || doc.data().delete) {
                    dispatch(closeMessage(messageList.docId, true))
                } else {
                    dispatch(userGetData(doc.data()))
                }

                // -----------
                if (messageList.users[0].a !== doc.data().meta.avatarThumb) {
                    let changeData = messageList.users
                    changeData[0].a = doc.data().meta.avatarThumb
                    firebase.firestore().collection("messagesList").doc(messageList.docId).update({
                        users: changeData
                    })
                }

                if (messageList.users[0].n !== doc.data().meta.nickname) {
                    let changeData = messageList.users
                    changeData[0].n = doc.data().meta.nickname
                    firebase.firestore().collection("messagesList").doc(messageList.docId).update({
                        users: changeData
                    })
                }

            } else {
                dispatch(closeMessage(messageList.docId, true))
            }
        })
        firebase.firestore().collection("users").doc(messageList.to[1]).get().then(doc => {
            if (doc.data()) {
                if (!doc.data().online) {
                    firebase.firestore().collection("users").doc(doc.id).update({
                        online: true
                    })
                }
                if (messageList.users[1].a !== doc.data().meta.avatarThumb) {
                    let changeData = messageList.users
                    changeData[1].a = doc.data().meta.avatarThumb
                    firebase.firestore().collection("messagesList").doc(messageList.docId).update({
                        users: changeData
                    })
                }
                if (messageList.users[1].n !== doc.data().meta.nickname) {
                    // firebase.firestore().collection("users").where("meta.nickname", "==", messageList.users[1].n).limit(1).get().then(doc=>{
                    //     doc.forEach(doc=>{
                    //sistem log 
                    firebase.firestore().collection("systemLogs").add({
                        n: "cc fake degisti " + messageList.docId + " - " + messageList.to[0] + " - " + messageList.to[1] + " - " + doc.id,
                        t: new Date()
                    })
                    let changeData = messageList.users
                    let changeTo = messageList.to
                    changeData[1].a = doc.data().meta.avatarThumb
                    changeData[1].n = doc.data().meta.nickname
                    changeTo[1] = doc.id
                    firebase.firestore().collection("messagesList").doc(messageList.docId).update({
                        users: changeData,
                        to: changeTo
                    })
                    //     })
                    // })
                }
                dispatch(fakeData(doc.data()))
            } else {
                dispatch(closeMessage(messageList.docId, true))
            }
        })
    }
}

const openMessageAdmin = () => {
    return async (dispatch, getState) => {
        dispatch(MESSAGES_DATA_RESET())

        dispatch(onChange("message", ""))
        dispatch(onChange("activeMessage", "admin"))
        if (window.innerWidth < 991) {
            dispatch(setMessageOpen(2))
        }
    }
}

const openMessageCheck = (userId_2, noOpen) => {
    return (dispatch, getState) => {
        dispatch(MESSAGES_DATA_RESET())

        dispatch(onChange("message", ""))
        const list = getState().messagesList.list ? getState().messagesList.list : [];
        const { docId, meta } = getState().auth.userData;

        let messageId = ""
        list.forEach(doc => {
            if (doc.to[0] === userId_2 || doc.to[1] === userId_2) {
                messageId = doc.id
            }
        })
        if (!noOpen) {
            dispatch(setMessageOpen(true, true))
        }
        firebase.firestore().collection("users").doc(userId_2).get().then(async userData => {
            if (!userData.data()) {
                return null
            }
            if (!messageId) {
                await firebase.firestore().collection('messagesList').where("to", "array-contains", docId).where("delete", "==", true).orderBy("allGetMessages", "desc").get().then(doc => {
                    doc.forEach(doc => {
                        if (!messageId) {
                            if (doc.data().to[0] === userId_2 || doc.data().to[1] === userId_2) {
                                messageId = doc.id
                            }
                        }
                    })
                })
            }
            if (!messageId) {

                let reality = true
                if (userId_2.startsWith(defaultFakeId)) { reality = false }
                let usersM = []
                usersM = [
                    {
                        a: meta.avatarThumb,
                        n: meta.nickname,
                    },
                    {
                        a: userData.data().meta.avatarThumb,
                        n: userData.data().meta.nickname,
                    }
                ]
                firebase.firestore().collection('messagesList').add({
                    callcenterId: 0,
                    callcenterList: "",
                    lastMessage: "",
                    lastSend: "",
                    type: "",
                    reality: reality,
                    status: false,
                    delete: false,
                    time: firebase_app.firestore.FieldValue.serverTimestamp(),
                    create: firebase_app.firestore.FieldValue.serverTimestamp(),
                    allGetMessages: 0,
                    allSendMessages: 0,
                    newMessages: 0,
                    asa: false,
                    to: [docId, userId_2],
                    users: usersM,
                    activeBox: false,
                    active: true
                }).then(doc => {
                    dispatch(openMessage(doc.id))
                })
                firebase.analytics().logEvent('create_message', { user1_nickname: meta.nickname, user2_nickname: userData.data().meta.nickname });
            } else {
                dispatch(openMessage(messageId, true))
            }
        })
    }
}

const deleteMessage = () => {
    return (dispatch, getState) => {
        const { activeMessage } = getState().form;
        firebase.firestore().collection('messagesList').doc(activeMessage).update({
            activeBox: false,
            delete: true,
            active: false,
        })
        dispatch(onChange("message", ""))
        dispatch(MESSAGES_DATA_RESET())
        if (window.innerWidth < 991) {
            dispatch(setMessageOpen(1))
        }
    }
}
const imageUploadStart = (image) => {
    return (dispatch, getState) => {
        dispatch(IMAGE_UPLOAD_START(image))
    }
}
const sendMessage = () => {
    return async (dispatch, getState) => {
        const fullYear = new Date().getFullYear() + "_" + new Date().getMonth()
        const { sendImage, data } = getState().messages;
        const form = getState().form;
        const userData = getState().auth.userData;
        let message = form.message
        let _type = data.type
        let _asa = true

        dispatch(clearMessages())
        // otomatik atanmis mesajsa
        if (data.type === "automessage") {
            _asa = false;
            _type = "";
        }
        try {
            firebase.firestore().collection('messagesList').doc(data.docId).update({
                lastMessage: message,
                lastSend: "callcenter",
                callcenterId: 0,
                type: _type,
                status: true,
                time: firebase_app.firestore.FieldValue.serverTimestamp(),
                allSendMessages: Number(data.allSendMessages) ? Number(data.allSendMessages) + 1 : 1,
                asa: _asa,
                newMessages: Number(data.newMessages) ? Number(data.newMessages) + 1 : 1,
                ccDisable: false,
            });
            if (sendImage) {
                firebase.firestore().collection('messages').add({
                    cc: data.ccDisable ? "disable_" + userData.docId : userData.docId,
                    buy: "",
                    get: data.to[0],
                    post: data.to[1],
                    id: data.docId,
                    message: message,
                    time: firebase_app.firestore.FieldValue.serverTimestamp(),
                    image: sendImage,
                    type: "photo",
                    asa: data.type,
                    visible: false,
                    coin: form.imageCoin
                })

            } else {
                firebase.firestore().collection('messages').add({
                    cc: data.ccDisable ? "disable_" + userData.docId : userData.docId,
                    buy: "",
                    get: data.to[0],
                    post: data.to[1],
                    id: data.docId,
                    message: message,
                    time: firebase_app.firestore.FieldValue.serverTimestamp(),
                    image: "",
                    type: "",
                    asa: data.type,
                    visible: false,
                })

            }

            if (!data.ccDisable) {
                // eger asa ise istatistik guncelle
                if (data.type === "Asa1" || data.type === "Asa2" || data.type === "Asa3" || data.type === "automessage" || data.type === "match") {
                    firebase.firestore().collection("callcenters").doc(userData.docId).collection("stat").doc(fullYear).get().then(doc => {
                        if (!doc || !doc.data()) {
                            firebase.firestore().collection("callcenters").doc(userData.docId).collection("stat").doc(fullYear).set({
                                [data.type.toLowerCase()]: 1
                            })
                        } else {
                            firebase.firestore().collection("callcenters").doc(userData.docId).collection("stat").doc(fullYear).update({
                                [data.type.toLowerCase()]: Number(doc.data()[data.type.toLowerCase()]) ? Number(doc.data()[data.type.toLowerCase()]) + 1 : 1
                            })
                        }
                    })
                    firebase.firestore().collection("callcenters").doc(userData.docId).update({
                        ["total_" + data.type.toLowerCase()]: Number(userData["total_" + data.type.toLowerCase()]) ? Number(userData["total_" + data.type.toLowerCase()]) + 1 : 1
                    })
                } else {
                    // eger normal mesajsa istatistikleri guncellle
                    firebase.firestore().collection("callcenters").doc(userData.docId).update({
                        total_post: Number(userData.total_post) + 1,
                    })

                    firebase.firestore().collection("callcenters").doc(userData.docId).collection("stat").doc(fullYear).get().then(doc => {
                        if (!doc || !doc.data()) {
                            firebase.firestore().collection("callcenters").doc(userData.docId).collection("stat").doc(fullYear).set({
                                post: 1
                            })
                        } else {
                            firebase.firestore().collection("callcenters").doc(userData.docId).collection("stat").doc(fullYear).update({
                                post: Number(doc.data().post) ? Number(doc.data().post) + 1 : 1
                            })
                        }
                    })
                }
            }
            dispatch(deleteImg())
        } catch (error) {
            console.log(error)
        }
    }
}
const buyMessageImageFree = (id) => {
    return (dispatch, getState) => {
        // const { coins } = getState().generalDataReducer;
        const { docId, coin } = getState().auth.userData;
        try {
            firebase.firestore().collection("messages").doc(id).update({
                buy: true
            })
        } catch (error) {
            dispatch(error(error.message, "error", true));
            console.log(error)
        }
        //history
        firebase.firestore().collection("history").add({
            t: "message_image",
            a: docId,
            c: 0,
            u: coin,
            time: firebase_app.firestore.FieldValue.serverTimestamp()
        })
    }
}
const buyMessageImage = (id) => {
    return (dispatch, getState) => {
        const { locale } = getState().preferences;
        const { coins } = getState().generalDataReducer;
        const { docId, coin } = getState().auth.userData;
        if (coins.messagePhotoCoin > coin) {
            dispatch(MESSAGES_FAIL({
                type: "coin",
                message: firebaseError("General.coinYeterliDegil", locale) + " (" + coins.messagePhotoCoin + " coin)"
            }))
        } else {
            try {
                firebase.firestore().collection("users").doc(docId).update({
                    coin: Number(coin) - Number(coins.messagePhotoCoin)
                })
                firebase.firestore().collection("messages").doc(id).update({
                    buy: true
                })
            } catch (error) {

                dispatch(error(error.message, "error", true));
                console.log(error)
            }
            //history
            firebase.firestore().collection("history").add({
                t: "message_image",
                a: docId,
                c: coins.messagePhotoCoin,
                u: coin,
                time: firebase_app.firestore.FieldValue.serverTimestamp()
            })
        }
    }
}
const deleteImg = () => {
    return (dispatch, getState) => {
        // const { active } = getState().messages;
        dispatch(MESSAGE_IMAGE_DELETE())
    }
}
const cleanError = () => {
    return (dispatch, getState) => {
        dispatch(MESSAGE_CLEAN_ERROR())
    }
}
const imageUploadEnd = (filename) => {
    return (dispatch, getState) => {
        dispatch(IMAGE_UPLOAD_END(filename))
    }
}

const closeMessage = (messageId, force) => {
    return (dispatch, getState) => {
        const { userData } = getState().auth;
        const messageData = getState().messages.data ? getState().messages.data : {};
        if (!messageId) {
            messageId = messageData.docId
        }
        dispatch(clearMessages())
        firebase.firestore().collection("callcenters").doc(userData.docId).update({
            activeMessageId: ""
        })
        if (force) {
            firebase.firestore().collection('messagesList').doc(messageId).update({
                callcenterId: 0,
                callcenterList: "",
                asa: false,
                lastSend: "callcenter"
            })
        } else {
            firebase.firestore().collection('messagesList').doc(messageId).update({
                callcenterId: 0,
                callcenterList: ""
            })
        }
    }
}

const sendWaitroom = () => {
    return (dispatch, getState) => {
        const { userData } = getState().auth;
        const messageData = getState().messages.data ? getState().messages.data : {};
        dispatch(clearMessages())
        firebase.firestore().collection("callcenters").doc(userData.docId).update({
            activeMessageId: ""
        })
        firebase.firestore().collection('messagesList').doc(messageData.docId).update({ callcenterId: 0, callcenterList: "", type: "waiting", oldType: messageData.type })
    }
}
const closeMessageSidebar = (id) => {
    return (dispatch, getState) => {
        firebase.firestore().collection('messagesList').doc(id).update({
            active: false,
            activeBox: false
        })
    }
}

const sendGift = (giftId, giftCoin, giftName, noOpen) => {
    return async (dispatch, getState) => {
        const userData = getState().auth.userData;
        const messagesData = getState().messages.data;
        dispatch(clearMessages())

        firebase.firestore().collection('messagesList').doc(messagesData.docId).update({
            lastMessage: giftName,
            lastSend: "callcenter",
            callcenterId: 0,
            type: "",
            status: true,
            time: firebase_app.firestore.FieldValue.serverTimestamp(),
            allSendMessages: Number(messagesData.allSendMessages) + 1 ? Number(messagesData.allSendMessages) + 1 : 0,
            asa: true,
            ccDisable: false

        });
        firebase.firestore().collection('messages').add({
            cc: messagesData.ccDisable ? "disable_" + userData.docId : userData.docId,
            buy: "",
            get: messagesData.to[0],
            post: messagesData.to[1],
            id: messagesData.docId,
            message: giftName,
            time: firebase_app.firestore.FieldValue.serverTimestamp(),
            image: giftId,
            type: "gift",
            asa: "",
            visible: false,
        })
        firebase.firestore().collection("callcenters").doc(userData.docId).update({
            activeMessageId: ""
        })

        // firebase.firestore().collection('messagesList').doc(messageId).update({
        //     lastMessage: giftName,
        //     lastSend: "user",
        //     type: "",
        //     status: true,
        //     time: firebase_app.firestore.FieldValue.serverTimestamp(),
        //     allGetMessages: Number(doc.data().allGetMessages) + 1 ? Number(doc.data().allGetMessages) + 1 : 0,
        //     asa: false,
        // });
        // firebase.firestore().collection('messages').add({
        //     cc: 0,
        //     buy: "",
        //     get: fakeId,
        //     post: docId,
        //     id: doc.id,
        //     message: giftName,
        //     time: firebase_app.firestore.FieldValue.serverTimestamp(),
        //     image: giftId,
        //     type: "gift",
        //     asa: "",
        //     visible: false,
        // })
    }
}

const sendGif = (gifUrl, gifModalSet) => {
    return async (dispatch, getState) => {
        const userData = getState().auth.userData;
        const messagesData = getState().messages.data;

        dispatch(clearMessages())

        firebase.firestore().collection('messagesList').doc(messagesData.docId).update({
            lastMessage: "gif",
            lastSend: "callcenter",
            callcenterId: 0,
            type: "",
            status: true,
            time: firebase_app.firestore.FieldValue.serverTimestamp(),
            allSendMessages: Number(messagesData.allSendMessages) + 1 ? Number(messagesData.allSendMessages) + 1 : 0,
            asa: true,
            ccDisable: false,
        });
        firebase.firestore().collection('messages').add({
            cc: messagesData.ccDisable ? "disable_" + userData.docId : userData.docId,
            buy: "",
            get: messagesData.to[0],
            post: messagesData.to[1],
            id: messagesData.docId,
            message: gifUrl,
            time: firebase_app.firestore.FieldValue.serverTimestamp(),
            image: "",
            type: "gif",
            asa: "",
            visible: false,
        })
        firebase.firestore().collection("callcenters").doc(userData.docId).update({
            activeMessageId: ""
        })
        gifModalSet("")
    }
}
// giftId, giftCoin, giftName, 
const sendFirstMessage = (fakeId, noOpen) => {
    return async (dispatch, getState) => {
        const { locale } = getState().preferences;
        const form = getState().form;
        let message = form.message
        const coins = getState().generalDataReducer.coins;
        const list = getState().messagesList.list ? getState().messagesList.list : [];
        const { docId, coin, meta, badge, verify } = getState().auth.userData;

        let messageCoin = Number(coins.messageCoin) ? Number(coins.messageCoin) : 0
        if (messageCoin === 0) {
            if (!badge) {
                dispatch(error(firebaseError("General.mesajIcinVipOlmalisin", locale), "premium", true));
                return null
            }
        } else if (coin < coins.messageCoin) {
            dispatch(MESSAGES_FAIL({
                type: "coin",
                message: firebaseError("General.coinYeterliDegil", locale) + " (" + coins.messageCoin + " coin)"
            }))
            return null
        }
        if (!verify) {
            dispatch(error(firebaseError("Dashboard.verifyBaslik", locale) + meta.email, "error", true));
            dispatch(sendEmail())
            return null
        }
        let messageId = ""
        list.forEach(doc => {
            if (doc.to[0] === fakeId || doc.to[1] === fakeId) {
                messageId = doc.id
            }
        })
        if (!noOpen) {
            dispatch(setMessageOpen(true))
        }
        firebase.firestore().collection("users").doc(fakeId).get().then(async userData => {
            if (!userData.data()) {
                return null
            }
            if (!messageId) {
                await firebase.firestore().collection('messagesList').where("to", "array-contains", docId).where("delete", "==", true).orderBy("allGetMessages", "desc").get().then(doc => {
                    doc.forEach(doc => {
                        if (!messageId) {
                            if (doc.data().to[0] === fakeId || doc.data().to[1] === fakeId) {
                                messageId = doc.id
                            }
                        }
                    })
                })
            }
            if (!messageId) {

                let reality = true
                if (fakeId.startsWith(defaultFakeId)) { reality = false }
                let usersM = []
                usersM = [
                    {
                        a: meta.avatarThumb,
                        n: meta.nickname,
                    },
                    {
                        a: userData.data().meta.avatarThumb,
                        n: userData.data().meta.nickname,
                    }
                ]

                firebase.firestore().collection('messagesList').add({
                    callcenterId: 0,
                    callcenterList: "",
                    lastMessage: message,
                    lastSend: "user",
                    type: messageCoin === 0 ? "waiting" : "",
                    reality: reality,
                    status: true,
                    delete: false,
                    time: firebase_app.firestore.FieldValue.serverTimestamp(),
                    create: firebase_app.firestore.FieldValue.serverTimestamp(),
                    allGetMessages: 1,
                    allSendMessages: 0,
                    newMessages: 0,
                    asa: false,
                    to: [docId, fakeId],
                    users: usersM,
                    activeBox: false,
                    active: true
                }).then(doc => {
                    firebase.firestore().collection('messages').add({
                        cc: 0,
                        buy: "",
                        get: fakeId,
                        post: docId,
                        id: doc.id,
                        message: message,
                        time: firebase_app.firestore.FieldValue.serverTimestamp(),
                        image: "",
                        type: "",
                        asa: "",
                        visible: false,
                    })
                    dispatch(openMessage(doc.id))
                })
                firebase.analytics().logEvent('create_message', { user1_nickname: meta.nickname, user2_nickname: userData.data().meta.nickname });
            } else {
                dispatch(openMessage(messageId))
            }
            //history
            firebase.firestore().collection("history").add({
                t: "send_message",
                a: docId,
                c: 0,
                u: Number(coin),
                time: firebase_app.firestore.FieldValue.serverTimestamp()
            })
        })
    }
}

const clearMessages = () => {

    return async (dispatch, getState) => {

        dispatch(CLEAR_MESSAGES())
        dispatch(CLEAR_USERS())
        dispatch(CLEAR_NOTES())
        dispatch(onChange("activeMessage", ""))

    }
}


export { openMessage2, sendWaitroom, getMessages, sendGif, messagesReset, sendFirstMessage, openMessageAdmin, sendGift, openMessage, openMessageCheck, deleteMessage, imageUploadStart, sendMessage, buyMessageImageFree, buyMessageImage, deleteImg, cleanError, imageUploadEnd, closeMessage, closeMessageSidebar, setMessageOpen };
