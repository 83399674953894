import { createReducer } from 'redux-act';

import {
    ADMIN_MESSAGES,
    WAITING_LIST_DATA,
    NEW_LIST_DATA,
    MESSAGES_LIST_RESET,
    ASA1_LIST_DATA,
    ASA2_LIST_DATA,
    ASA3_LIST_DATA,
    FIRST_LIST_DATA,
    TOTAL_NEW_MESSAGES
} from '../actions/messagesList';

const initialState = {};

export const messagesList = createReducer(
    {
        [ADMIN_MESSAGES]: (state, payload) => {
            return ({ ...state, adminMessages: payload })
        },
        [WAITING_LIST_DATA]: (state, payload) => ({
            ...state,
            waiting: payload
        }),
        [NEW_LIST_DATA]: (state, payload) => ({
            ...state,
            new: payload
        }),
        [ASA1_LIST_DATA]: (state, payload) => ({
            ...state,
            asa1: payload
        }),
        [ASA2_LIST_DATA]: (state, payload) => ({
            ...state,
            asa2: payload
        }),
        [ASA3_LIST_DATA]: (state, payload) => ({
            ...state,
            asa3: payload
        }),
        [FIRST_LIST_DATA]: (state, payload) => ({
            ...state,
            first: payload
        }),
        [MESSAGES_LIST_RESET]: (state, payload) => ({
            list: []
        }),
        [TOTAL_NEW_MESSAGES]: (state, payload) => ({
            ...state,
            totalNewMessages: payload,
            error: ""
        })
    },
    initialState
);
