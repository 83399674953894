import { createReducer } from 'redux-act';

import {
    USERS_INIT,
    USERS_FAIL,
    PROFILE_USERS,
    TWO_USERS_SUCCESS,
    FAKE_DATA,
    USER_DATA,
    USERS_RESET
} from '../actions/users';
import {
  CLEAR_USERS
} from '../actions/messages';


const initialState = {};

export const users = createReducer(
  {
    [USERS_INIT]: (state, payload) => {
      return({
        ...state,
        loading: true
      })
    },
    [FAKE_DATA]: (state, payload) => {
      return({
        ...state,
        fake: payload
      })
    },
    [USER_DATA]: (state, payload) => {
      return({
        ...state,
        user: payload
      })
    },
    [USERS_RESET]: (state, payload) => {
      return({
        loading: false
      })
    },
    [USERS_FAIL]: (state, payload) => {
      return({
        ...state,
        loading: false
      })
    },
    [TWO_USERS_SUCCESS]: (state, payload) => {
      return({
        ...state,
        twoUsers: payload.twoUsers,
        loading: false
      })
    },
    [CLEAR_USERS]: (state, payload) => {
      return({
        ...state,
        fake: {},
        user: {},
      })
    },
    [PROFILE_USERS]: (state, payload) => {
      return({
        ...state,
        ...payload,
        loading: false
      })
    }
  },
  initialState
);
