import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Layout.module.scss';
import { useDispatch } from 'react-redux';
import { useLocation} from "react-router-dom";
import Header from './Header/Header';
// import HeaderMobile from './Header/Header_mobile_3';
// import LastUsers from './LastUsers/LastUsers_3';
// import MobilMenu from './MobilMenu/Index';
// import Footer from './Footer/Footer_3';
import {  waitingListData, newListData, asaListData, firstListData } from "../../state/actions/messagesList"
import { statData } from "../../state/actions/stats"
import { getFriends } from "../../state/actions/friends"
// import { onChange} from "../../state/actions/form"
import classNames from 'classnames';
// import {Faq, giftsData} from '../../state/actions/general';

const Layout = ({ children }) => {
    const dispatch = useDispatch();
    let location = useLocation();
    useEffect(() => {
        dispatch(statData())
        dispatch(waitingListData())
        dispatch(newListData())
        dispatch(asaListData())
        dispatch(firstListData())
        dispatch(getFriends())
        // dispatch(vipUsers(20))
        // dispatch(getFriends())
        // dispatch(Faq())
        // dispatch(giftsData())
        // dispatch(onChange("searchNickname", ""))
        // dispatch(matchPopupData())
        // eslint-disable-next-line
    }, [dispatch]);
    // openMessage
        return (
            <div className={styles.Layout} style={{ backgroundColor: "#f6f6f6", minHeight: "100%" }}>
                    <Header />
                <div className={classNames("container", styles.general)}>
                    {/* <div className={styles.left}>
                        
                        <LastUsers />
                    </div> */}
                    <div className={classNames(styles.content)}>
                        {children}
                    </div>
                </div>
                {/* <Footer /> */}
                {/* <Coins /> */}
            </div>
        );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Layout;
