import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import styles from './Home.module.scss';
// import Odometer from 'react-odometerjs';
import Login from "../../components/template_1/Login"
// import Contact from "../../components/template_1/Contact"
import Modal from "../../components/Items/modal"
import { Navigate } from 'react-router-dom';
import paths from '../../Router/paths';
import { cleanErrorAuth } from "../../state/actions/auth"
import { defaultLogoWhite } from "../../Settings"
import { FormattedMessage, useIntl } from 'react-intl';
// import VerifyAlert from "../../components/Items/verifyAlert"

const Index = () => {
  const intl = useIntl();
  const { site, isAuth, auth } = useSelector(
    (state) => ({
      site: state.generalDataReducer.site ? state.generalDataReducer.site[1] : "",
      isAuth: state.auth.userData.docId,
      auth: state.auth,
    }), shallowEqual
  );
  const [mobileMenu, mobileMenuStatus] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
  }, [dispatch]);
  const redirect = isAuth && <Navigate to={paths.DASHBOARD} />;
  return (
    <div id="landingPage_1" className={styles.landingPage_1}>
      {redirect}
      <div className={classNames(styles.left, "text-center")} style={{ backgroundImage: `url(${site ? site.bg : ""})` }}>
        <div className={styles.logo}>
          <div className={styles.img_wrap}>
            <NavLink to="/" exact>
              {defaultLogoWhite}
              {/* <Logo color="#fff" width="190px" height="140px" /> */}
            </NavLink>
          </div>

        </div>
      </div>
      <div className={classNames(styles.right, mobileMenu ? styles.active : "")} id="right">
        <div className={styles.login}>
          <Login />
        </div>
      </div>

      {auth.error && auth.error.type ? <Modal title={auth.error.type === "success" ? intl.formatMessage({ id: "General.basarili" }) : intl.formatMessage({ id: "General.hata" })} content={auth.error.message} status={auth.error.type} width={400} close={(e) => dispatch(cleanErrorAuth())} /> : ""
      }
    </div>
  );
};

export default Index;
