import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from './MessageBox_2.module.scss';
import classnames from 'classnames';
// import TimeAgo from 'javascript-time-ago'
// import en from 'javascript-time-ago/locale/en'
// import {FormattedMessage} from 'react-intl';
import MessageList from "./container/MessageList"
import Footer from "./container/Footer"
import Stats from "./container/Stats"
import Note from "./container/Note"

// TimeAgo.addLocale(en)
// const timeAgo = new TimeAgo('en-US')

const MessageBox = (props) => {
    const { messagesData } = useSelector(
        (state) => ({
            messagesData: state.messages.data,
        }), shallowEqual
    );

    // const [friendStatus, friendStatusSet] = useState(false);
    // // const [modal_1, modal_1Status] = useState("");
    // const [modal_2, modal_2Set] = useState("");

    // useEffect(() => {
    //     if(users.docId && fakes.docId){
    //         firebase.firestore().collection("friends").where("a", "==", users.docId).where("b", "==", fakes.docId).get().then(doc=>{
    //             doc.forEach(doc => {
    //                 if(!doc.data().s && !doc.data().cc){
    //                     if(!doc.data().cc){
    //                         friendStatusSet("waiting")
    //                     }
    //                 }else{
    //                     friendStatusSet(true)
    //                 }
    //             });
    //         })
    //     }
    //     // eslint-disable-next-line
    // }, [users, fakes]);

    if (!messagesData || !messagesData.docId) {
        return null;

    }
    return (
        <div className={classnames(styles.messageBox_2, props.id ? styles.messagesPage : "")}>
            {/* <div className={styles.buttons}>
                {friendStatus === "waiting" ? <ButtonPrimary onClick={(e) => {dispatch(verifyFriend(users.docId, fakes.docId));friendStatusSet(true)}} style={{height:34, width:120, borderRadius:5, textTransform:"none", marginRight:10}} text="Confirm Friend" /> : friendStatus === false ? <ButtonPrimary onClick={(e) => {dispatch(addFriend(users.docId, fakes.docId));friendStatusSet(true)}} style={{height:34, width:120, borderRadius:5, textTransform:"none", marginRight:10}} text="Add Friend" /> : ""}
                <ButtonSecondary2 onClick={(e) => modal_2Set(true)} style={{height:34, width:120, borderRadius:5, textTransform:"none", marginRight:10}} text="Old Photos" />
                <ButtonSecondary style={{height:34, width:120, borderRadius:5, textTransform:"none", marginRight:10}} text="Wait Room" onClick={(e) => dispatch(sendWaitroom())} />
                <ButtonDanger style={{height:34, width:80, borderRadius:5, textTransform:"none", marginLeft:"auto"}} text="Close" onClick={(e) => dispatch(closeMessage(""))} />
            </div>
            {fakes.note ?<div className={styles.info}>
                {fakes.note}
            </div> : ""}
            <Note />
            <div style={{width:"100%", display:"flex", marginBottom:10}}>
                <input style={{flex:1, height:36, border:0, padding:"0 6px", borderTopLeftRadius:3, borderBottomLeftRadius:3}} type="text" id="noteCallcenter" placeholder="Notizen" />
                <button style={{border: 0, background: "#416BAA", color: "#fff", fontSize: 14, padding: "0 8px", height: 36, borderTopRightRadius:3, borderBottomRightRadius:3}} onClick={(e) => dispatch(addNote())}>
                speichern
                </button>
            </div>
            <div style={{display:"flex", marginTop:0,marginBottom:10, background:"#fff", padding:8, fontSize:12, textAlign:"center", color:"#416BAA", fontWeight:"600", borderRadius:3}}>
                  <div style={{flex:1, display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <svg  style={{marginRight:6, color:"#ecc32e"}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" size="25" height="25" width="25" xmlns="http://www.w3.org/2000/svg"><path d="M264.4 95.01c-35.6-.06-80.2 11.19-124.2 34.09C96.27 152 61.45 182 41.01 211.3c-20.45 29.2-25.98 56.4-15.92 75.8 10.07 19.3 35.53 30.4 71.22 30.4 35.69.1 80.29-11.2 124.19-34 44-22.9 78.8-53 99.2-82.2 20.5-29.2 25.9-56.4 15.9-75.8-10.1-19.3-35.5-30.49-71.2-30.49zm91.9 70.29c-3.5 15.3-11.1 31-21.8 46.3-22.6 32.3-59.5 63.8-105.7 87.8-46.2 24.1-93.1 36.2-132.5 36.2-18.6 0-35.84-2.8-50.37-8.7l10.59 20.4c10.08 19.4 35.47 30.5 71.18 30.5 35.7 0 80.3-11.2 124.2-34.1 44-22.8 78.8-52.9 99.2-82.2 20.4-29.2 26-56.4 15.9-75.7zm28.8 16.8c11.2 26.7 2.2 59.2-19.2 89.7-18.9 27.1-47.8 53.4-83.6 75.4 11.1 1.2 22.7 1.8 34.5 1.8 49.5 0 94.3-10.6 125.9-27.1 31.7-16.5 49.1-38.1 49.1-59.9 0-21.8-17.4-43.4-49.1-59.9-16.1-8.4-35.7-15.3-57.6-20zm106.7 124.8c-10.2 11.9-24.2 22.4-40.7 31-35 18.2-82.2 29.1-134.3 29.1-21.2 0-41.6-1.8-60.7-5.2-23.2 11.7-46.5 20.4-68.9 26.1 1.2.7 2.4 1.3 3.7 2 31.6 16.5 76.4 27.1 125.9 27.1s94.3-10.6 125.9-27.1c31.7-16.5 49.1-38.1 49.1-59.9z"></path></svg>
                        {users.coin} Coin
                  </div>
                  <div className={users.online ? styles.online : ""} style={{flex:1, display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <svg style={{marginRight:6}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 496 512" size="20" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z"></path></svg>
                    {users.online ? "online" : users.lastLogin && timeAgo.format(new Date(users.lastLogin.seconds*1000), 'twitter')}
                  </div>
                  <div style={{flex:1, display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <svg  style={{marginRight:6}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" size="22" height="22" width="22" xmlns="http://www.w3.org/2000/svg"><path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-2 12H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z"></path></svg>
                    
                    {users.timestamp && new Date(users.timestamp.seconds*1000).getMonth() + "/" + new Date(users.timestamp.seconds*1000).getDate() + "/" + new Date(users.timestamp.seconds*1000).getFullYear()}
                  </div>
                  <div style={{flex:1, display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <svg style={{marginRight:6}} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" size="20" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M8 256C8 119 119 8 256 8s248 111 248 248-111 248-248 248S8 393 8 256zm292 116V256h70.9c10.7 0 16.1-13 8.5-20.5L264.5 121.2c-4.7-4.7-12.2-4.7-16.9 0l-115 114.3c-7.6 7.6-2.2 20.5 8.5 20.5H212v116c0 6.6 5.4 12 12 12h64c6.6 0 12-5.4 12-12z"></path></svg>
                    OUT {messagesData.allSendMessages}
                  </div>
                  <div style={{flex:1, display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" size="20" height="20" width="20" xmlns="http://www.w3.org/2000/svg" style={{marginRight:6}}><path d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zM212 140v116h-70.9c-10.7 0-16.1 13-8.5 20.5l114.9 114.3c4.7 4.7 12.2 4.7 16.9 0l114.9-114.3c7.6-7.6 2.2-20.5-8.5-20.5H300V140c0-6.6-5.4-12-12-12h-64c-6.6 0-12 5.4-12 12z"></path></svg>
                    IN {messagesData.allGetMessages}
                  </div>
            </div> */}
            
            <Stats />
            <Note />
            <Footer />
            <MessageList />
            <div className={styles.userBtn} onClick={(e) => {document.getElementById("user").classList.toggle("show")}}>
                USER
            </div>
            <div className={styles.fakeBtn} onClick={(e) => {document.getElementById("fake").classList.toggle("show")}}>
                FAKE
            </div>
            {/* {modal_2 ?<Modal title="Old Images" content={<ul className={styles.oldImages}>
                {messages.images && messages.images.length ? messages.images.map((doc, index) =>{
                    return (<li key={index}><img src={doc} alt="" /></li>)
                }) : ""}
            </ul>} status={modal_2} width={600} close={(e) => modal_2Set("")} /> : ""} */}
            {/* {modal_1 ? <Modal title={<FormattedMessage id="General.bilgi" />} content={<FormattedMessage id="General.mesajSilmeUyari" />} buttons={<div><ButtonDark onClick={(e) => modal_1Status("")} style={{ flex: 1, borderRadius:10, height:50 }} text={<FormattedMessage id="General.hayir" />} /><ButtonDanger onClick={(e) => { modal_1Status(""); dispatch(deleteMessage()) }} style={{ flex: 1, borderRadius:10, height:50 }} text={<FormattedMessage id="General.evet" />} /></div>} status={modal_1} width={500} close={(e) => modal_1Status("")} /> : ""} */}
        </div>)
}
export default MessageBox;
