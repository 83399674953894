
import { createAction } from 'redux-act';
import firebase from '../../firebase.js';
// import visitorInfo from 'visitor-info';
import { publicIpv4 } from 'public-ip';
import firebase_app from 'firebase/compat/app';
const oldRealTimeDb = firebase.database();


export const LOG_DATA = createAction('LOG_DATA');
export const LOG_ID = createAction('LOG_ID');
export const logUser = () => {
    return (dispatch, getState) => {
        const userData = getState().auth.userData;
        const logs = getState().logs;
        //     console.log("setOInline2")
        oldRealTimeDb.ref('.info/connected').on('value', snapshot => {
            oldRealTimeDb.ref(`/onlineCallcenter/${userData.docId}`).onDisconnect().remove().then(() => {
                oldRealTimeDb.ref(`/onlineCallcenter/${userData.docId}`).set(userData.docId);
            })
        })
        try {
            if (!userData.delete && !userData.banned && logs.id && !userData.online) {
                firebase.firestore().collection('logs').doc(logs.id).update({
                    u: userData.docId,
                    // s: true
                    // n: userData.meta.nickname ? userData.meta.nickname : "",
                    // a: userData.meta.avatarThumb ? userData.meta.avatarThumb : "",
                })

            }
        } catch (error) {
            console.log(error)
        }
    }

}
export const logsUpdate = (userId) => {
    return (dispatch, getState) => {
        const { id } = getState().logs;
        // const userData = getState().auth.userData;
        if (id) {
            firebase.firestore().collection('logs').doc(id).update({
                u: userId,
                // s: true
                // n: userData.meta.nickname ? userData.meta.nickname : "",
                // a: userData.meta.avatarThumb ? userData.meta.avatarThumb : "",
            })
        }
    }
}
export const logsData = (size) => {
    return (dispatch, getState) => {
        try {
            publicIpv4().then(ipAddress => {
                var __url_string = window.location.href;
                var __url = new URL(__url_string);
                let ref = __url.searchParams.get("ref") ? __url.searchParams.get("ref") : ""
                const userData = getState().auth.userData;
                let b = "";
                // if (visitorInfo().browser.name && visitorInfo().browser.version) {
                //     b = visitorInfo().browser.name + " - " + visitorInfo().browser.version
                // }
                // let o = ""
                // if (visitorInfo().os.name && visitorInfo().os.version) {
                //     o = visitorInfo().os.name + " - " + visitorInfo().os.version
                // }
                firebase.firestore().collection('logs').add({
                    i: ipAddress,
                    b: b,
                    // c: visitorInfo().country.alpha3 ? visitorInfo().country.alpha3 : "",
                    // d: visitorInfo().device.model ? visitorInfo().device.model : "",
                    // o: o,
                    t: firebase_app.firestore.FieldValue.serverTimestamp(),
                    r: ref,
                    u: userData.docId ? userData.docId : "",
                    // s: true
                    // n: userData.meta.nickname ? userData.meta.nickname : "",
                    // a: userData.meta.avatarThumb ? userData.meta.avatarThumb : "",
                    // r: userData.docId ? "user" : ""
                })


            })
        } catch (error) {

        }
        // var x = document.referrer;
        // firebase.firestore().collection('favs').where("a", "==", docId).orderBy("t", "desc").onSnapshot(doc => {
        //     let arr = []
        //     doc.forEach(doc => {
        //         arr.push(doc.data().b)
        //     })
        //     if (arr.length) {
        //         dispatch(FAVS_LIST(arr))
        //     }
        // })
    };
};