import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import rootReducer from './reducers/Index';
import { logsData} from './actions/logs';
import { verifyAuth} from './actions/auth';
// import { messagesListReset} from './actions/messagesList';
// import { messagesReset} from './actions/messages';
// import { clearDashboard} from './actions/dashboard';
import {defaultColorsSet, siteData, giftsData } from './actions/general';
import {getCallcenters } from './actions/callcenters';

export const configureStore = initialState => {
  const middlewares = [];

  const composeEnhancers =
    (process.env.NODE_ENV === 'development'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : null) || compose;

  middlewares.push(applyMiddleware(thunk));

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(...middlewares)
  );
  // console.log(middlewares)
  // store.removeItem('persist:root')
  store.dispatch(logsData());
  setTimeout(() => {
    store.dispatch(verifyAuth());
    store.dispatch(getCallcenters());
  }, 1);
  // store.dispatch(generalData());
  store.dispatch(siteData());
  // store.dispatch(infoData());
  // store.dispatch(prizeData());
  // store.dispatch(totalLength());
  // store.dispatch(Packets());
  // store.dispatch(Faq());
  store.dispatch(giftsData());
  // store.dispatch(clearDashboard());
  defaultColorsSet();
  // store.dispatch(messageReset());
  // store.dispatch(logout());
  const persistor = persistStore(store);

  return { store, persistor };
};
