

import firebase from '../../firebase.js';
import {defaultFakeId} from "../../Settings"
import { createAction } from 'redux-act';
import moment from "moment"
export const STATS = createAction('STATS');


const statData = (customDate) =>{
    return (dispatch, getState) => {
        const userData = getState().auth.userData;
        const form = getState().form;
        let today = new Date(moment().startOf('day'))  
        if(customDate === "reload" && form.statDate){
            today = form.statDate
        }
        if(customDate === "yesterday"){
            today = new Date(moment().subtract(1, 'days').startOf('day')) 
        }
        if(customDate === "week"){
            today = new Date(moment().startOf('week')) 
        }
        if(customDate === "month"){
            today = new Date(moment().startOf('month')) 
        }
        if(customDate === "total"){
            today = new Date(moment("01/01/2000", "DD/MM/YYYY")) 
        }
        firebase.firestore().collection("messages").where("time", ">=", today).where("cc", "==", userData.docId).get().then(doc=>{
            let _in = 0;
            let _out = 0;
            let _asa1 = 0;
            let _asa2 = 0;
            let _asa3 = 0;
            doc.forEach(doc=>{
                if(doc.data().asa === "Asa1"){
                    _asa1++
                }else if(doc.data().asa === "Asa2"){
                    _asa2++
                }else if(doc.data().asa === "Asa3"){
                    _asa3++
                }else if(doc.data().asa === "Asa3"){
                    _asa3++
                }else if(doc.data().type === "" && doc.data().asa === ""){
                    if(doc.data().post.split("_")[0] === defaultFakeId){
                        _out++
                    }else{
                        _in++
                    }
                }else if(doc.data().type === "photo" && doc.data().asa === ""){
                    if(doc.data().post.split("_")[0] === defaultFakeId){
                        _out++
                    }else{
                        _in++
                    }
                }
            })
            dispatch(STATS({
                asa1:_asa1,
                asa2:_asa2,
                asa3:_asa3,
                in:_in,
                out:_out,
            }))
        })
    }
}
export {statData}