import { createReducer } from 'redux-act';

import {
    CALLCENTER_DATA,
} from '../actions/callcenters';

const initialState = {};

const stat = createReducer(
    {
        [CALLCENTER_DATA]: (state, payload) => ({
            list:payload.list,
            name:payload.name
        })
    },
    initialState
);
export default stat