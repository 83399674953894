import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from './Waiting.module.scss';
import { openMessage2, closeMessage } from "../../../state/actions/messages"
import classnames from 'classnames';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')
const MessagesList = (props) => {
    const dispatch = useDispatch();
    const { messagesList } = useSelector(
        (state) => ({
            messagesList: state.messagesList.waiting ? state.messagesList.waiting : []
        }), shallowEqual
    );
    return (<div className={classnames(styles.general)}>
        <div className={styles.messagesList}>
            <ul id="messageList">
                {messagesList.map((post, index) => {
                    if (!post.users || !post.users[0] || !post.users[1]) {
                        return null
                    }
                    return (<li id={post.id} key={index} className={post.callcenterId ? styles.disable : ''}>
                        <div className={styles.content} onClick={(e) => post.callcenterId ? "" : dispatch(openMessage2(post))}>
                            <div className={styles.avatarContent}>
                                <div className={styles.avatar} style={{ backgroundImage: "url(" + post.users[0].a + ")" }}></div>
                            </div>
                            <div className={styles.text}>
                                <div className={styles.nickname}>
                                    <b>
                                        {post.users[0].n}
                                    </b>
                                    <small>VS</small>
                                    <b>
                                        {post.users[1].n}
                                    </b>
                                </div>
                                {/* <small>{post.time ? timeAgo.format(new Date(post.time.seconds * 1000), 'twitter') : ""}</small> */}
                                <p className={styles.messageSmall}>{post.lastMessage.substring(0, 20)}...</p>
                            </div>
                            <div className={styles.avatarContent}>
                                <div className={styles.avatar} style={{ backgroundImage: "url(" + post.users[1].a + ")" }}></div>
                            </div>
                        </div>
                        <div className={styles.close} onClick={(e) => dispatch(closeMessage(post.docId, true))}>x</div>
                    </li>)
                })}

            </ul>
        </div>
    </div>)
}
export default MessagesList;
