import React from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import Layout from "../components/Layout/Layout"
// import Home3 from '../pages/Template_3/Index';
// import Home2 from '../pages/Template_2/Index';
import Home from '../pages/Home/Index';
import Dashboard from '../pages/Dashboard/Index';
import paths from './paths';
// import Modal from "../components/Items/modal"
// import { cleanError } from '../state/actions/auth'
// import { clearUsers } from '../../state/actions/users'
// import { clearDashboard } from '../../state/actions/dashboard'
// import VerifyAlert from "../components/Items/verifyAlert"
import { ToastProvider } from 'react-toast-notifications'
// import {defaultHomeTemplate} from "../Settings"
// import {useIntl} from 'react-intl';

const RouterComponent = () => {
  return (
    <BrowserRouter>
      <ToastProvider>
        <Routes>
          <Route exact path="/dashboard" element={<PrivateRoute2><Dashboard /></PrivateRoute2>} />
          <Route path={paths.ROOT} element={<Home />} />
          <Route element={<Home />} />
        </Routes>
      </ToastProvider>
    </BrowserRouter>
  );
};

const PrivateRoute2 = ({ children }) => {
  const { id } = useSelector(
    state => ({
      id: state.auth.userData.docId
    }),
    shallowEqual
  );

  if (id) {
    return <Layout>{children}</Layout>
  } else {
    return <Navigate to={paths.ROOT} />
  }
};

export default RouterComponent;
