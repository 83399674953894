import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from '../MessageBox_2.module.scss';
// import classnames from 'classnames';
import axios from "axios";
// import TimeAgo from 'javascript-time-ago'
// import en from 'javascript-time-ago/locale/en'
import Input from "../../../Items/input"
import { onChange } from "../../../../state/actions/form"
import { sendGif, sendMessage } from "../../../../state/actions/messages"
import Modal from "../../../Items/modal"
import {ButtonDanger2} from "../../../Items/buttons"
import { emojiList, TenorKey, defaultMinCharacter} from "../../../../Settings"
import GiftsList from "../../GiftList/GiftList"
import {FormattedMessage, useIntl} from 'react-intl';
import GifIcon from "../../../../assets/img/icons/gif.svg"
import GiftIcon from "../../../../assets/img/icons/gift.svg"
// import PhotosDarkIcon from "../../../../assets/img/icons/photoDark.svg"
import SearchIcon from "../../../../assets/img/icons/search.svg"
// import CloseWhiteIcon from "../../../../assets/img/icons/closeWhite.svg"
import SmileIcon from "../../../../assets/img/icons/smile.svg"
import CCPhotos from "./CCPhotos"

// TimeAgo.addLocale(en)
// const timeAgo = new TimeAgo('en-US')

const Footer = (props) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { form} = useSelector(
        (state) => ({
            // messageList: state.messages && state.messages.list ? state.messages.list : [],
            // messages: state.messages,
            form: state.form,
            // users:state.users.user ? state.users.user : {},
            // fakes:state.users.fake ? state.users.fake : {},
            // callcentersName: state.callcenters.name ? state.callcenters.name : {},
            // gifts: state.generalDataReducer.gifts ? state.generalDataReducer.gifts : [],
        }), shallowEqual
    );

    // const [ccImage, ccImageSet] = useState(false);
    // const [modal_Image, modal_ImageStatus] = useState("");
    const [gifModal, gifModalSet] = useState("");
    const [gifLimit, gifLimitSet] = useState(10);
    const [gifList, gifListSet] = useState([]);
    const [giftModal, giftModalStatus] = useState("");
    const [loading, loadingSet] = useState(false);

    const emojiSet = (emojiObject) => {
        let html = form.message
        dispatch(onChange("message", (html+emojiObject)))
    };


    const getGif = (limit) => {
        let search = form.searchGif ? form.searchGif : ""
        if(gifLimit  !== limit){
            gifLimitSet(limit)
        }
        axios.get('https://g.tenor.com/v1/search?q='+search+'&key='+TenorKey+'&limit='+limit).then( (response) => {
            let arr = []
            response.data.results.forEach(doc=>{
                arr.push(doc.media[0].gif.url)
            })
            gifListSet(arr)
        }).catch( (error) => {
            console.log(error);
        })
    }
    const trendGif = (limit) => {
        if(gifList.length){
            return null
        }
        axios.get('https://g.tenor.com/v1/trending?key='+TenorKey+'&limit='+limit).then( (response) => {
            let arr = []
            response.data.results.forEach(doc=>{
                arr.push(doc.media[0].gif.url)
            })
            gifListSet(arr)
        }).catch( (error) => {
            console.log(error);
        })
    }
    const emojiStatus = (status) =>{
        if(status){
            document.getElementById("emoji").classList.add("open")
            document.getElementById("emojiBg").classList.add("open")
        }else{
            document.getElementById("emoji").classList.remove("open")
            document.getElementById("emojiBg").classList.remove("open")
        }
    }
    const enter = (e) => {
        if (e.keyCode === 13 && form.sendOnEnter) {
            e.preventDefault()
            if(!loading){
                loadingSet(true);
                dispatch(sendMessage());
            }
        }
    }
    // console.log(form.message);
    return (
            <footer className={styles.footer}>
                <div className={styles.messageGeneral}>
                    <div>
                        <div className={styles.inputContent}>
                            <textarea
                            id="messageTexarea" name="message"
                             onKeyDown={(e) => enter(e)}
                            onChange={(e) => dispatch(onChange("message", e.target.value))}
                            // onChange={(e) => this.messageChange(e)}
                            style={{height:100, width:"100%", border:0}} 
                            value={form.message}
                            className={styles.messageTextarea}
                            ></textarea>
                            <p style={{margin:0, color:"#416BAA", fontSize:12, fontWeight:"bold"}}>Min Character {defaultMinCharacter} / {form.message.length ? form.message.length : 0}</p>
                            {/* <MessageInput id="messageInput" name="message" styletype="type_3" type="text" label="" placeholder={sendPhotoMessage} /> */}
                        </div>
                    </div>
                    <div className={styles.icons}>
                        {form.message && (form.message.length > defaultMinCharacter) && !loading ? <ButtonDanger2 onClick={(e) => {loadingSet(true);dispatch(sendMessage())}} style={{height:34, width:80, borderRadius:5, textTransform:"none"}} text={intl.formatMessage({id:"General.gonder"})} /> :  <ButtonDanger2 style={{height:34, width:80, borderRadius:5, textTransform:"none", opacity:.5, cursor:"auto"}} text={intl.formatMessage({id:"General.gonder"})} />}
                        <CCPhotos />
                        <div className={styles.icon} style={{overflow:"inherit"}}>
                            <img className="cursor-pointer" style={{width:18}} src={SmileIcon} onClick={(e) => emojiStatus("true")} alt="" />

                            <div id="emoji" className={styles.emoji}>
                                <ul>
                                    {emojiList.map((doc, index)=>{
                                        return(<li key={index} onClick={(e) => emojiSet(doc)}>{doc}</li>)
                                    })}
                                </ul>
                            </div>

                            <div id="emojiBg" onClick={(e) => emojiStatus("")} className={styles.emojiBg}></div>
                        </div>
                        <div className={styles.icon} onClick={(e) => {gifModalSet("true"); trendGif(10)}}>
                            <img src={GifIcon} alt="" />
                        </div>
                        <div className={styles.icon} onClick={(e) => {giftModalStatus("true")}}>
                            <img src={GiftIcon} style={{width:18, height:18}} alt="" />

                        </div>
                        <div className={styles.checkbox}>
                            <label>
                                <input type="checkbox" name="sendOnEnter" defaultChecked={form.sendOnEnter} onChange={(e) => { dispatch(onChange("sendOnEnter", e.target.checked))}}/>
                                <FormattedMessage id="General.enterTusuylaGonder" />
                            </label>
                        </div>
                        {gifModal ? <div className={styles.gifModal}>
                            <div className={styles.input}>
                                <Input style={{marginBottom:0}} name="searchGif" type="text" label="Suche gif..." />
                                <img src={SearchIcon} alt="" onClick={(e) => getGif(10)} />
                            </div>
                            <ul>
                                {gifList.map((doc, index)=>{
                                    return(<li key={index} onClick={(e) => dispatch(sendGif(doc, gifModalSet))}>
                                        <img src={doc} alt="" />
                                    </li>)
                                })}
                            </ul>
                            {gifList.length ? <span className={styles.load} onClick={(e) => getGif(gifLimit+10)}>Mehr laden</span> : ""}
                        </div> : ""}
                        {gifModal ? <div onClick={(e) => gifModalSet("")} className={styles.gifBg}></div> : ""}
                        
                    </div>
                </div>
            {giftModal ? <Modal content={<GiftsList close={giftModalStatus} />} status={giftModal} width={700} close={(e) => giftModalStatus("")} type="buyGift" /> : ""}

            </footer>)
}
export default Footer;
