import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { logout } from '../../../state/actions/auth'
import classNames from 'classnames';
import styles from './Header.module.scss';
import { statData } from "../../../state/actions/stats"
import { onChange } from "../../../state/actions/form"
import path from "../../../Router/paths"
import {defaultLogo} from "../../../Settings"
import {  NavLink, useLocation } from "react-router-dom";
import {FormattedMessage} from 'react-intl';
import Modals from '../Modals/Index';
import ModalMessage from '../Modals/Messages';
import Select from '../../Items/select';
// import SearchIcon from "../../../assets/img/icons/search.svg"
import ReloadIcon from "../../../assets/img/icons/reload.svg"
// import BarsIcon from "../../../assets/img/icons/bars.svg"
import PowerIcon from "../../../assets/img/icons/power.svg"
import downWhiteIcon from "../../../assets/img/icons/downWhite.svg"
import Search2Icon from "../../../assets/img/icons/search2.svg"
import ChatIcon from "../../../assets/img/icons/chat.svg"
import FireIcon from "../../../assets/img/icons/fire.svg"
import LookIcon from "../../../assets/img/icons/look.svg"
import FriendsIcon from "../../../assets/img/icons/friends.svg"

const Header_3 = () => {
    const dispatch = useDispatch();
    const [dropdown, setDropdown] = useState(false);
    // const { addToast } = useToasts()
    // const [openMenu, setOpenMenu] = useState(false);
    // const [hideMenu, setHideMenu] = useState(false);
    let location = useLocation();

    const { auth, stats} = useSelector(
        (state) => ({
            auth: state.auth,
            stats: state.stats ? state.stats : {}
        }), shallowEqual
    );
    useEffect(() => {
        // setOpenMenu(false)
        setDropdown(false)
        // eslint-disable-next-line
    }, [location]);
    // useEffect(() => {
        // const windowScroll = (event) => {
        //     if(window.scrollY > 20 && !hideMenu){
        //         setHideMenu(true)
        //     }else if(window.scrollY < 20 && hideMenu){
        //         setHideMenu(false)
        //     }
        // }

        // window.addEventListener('scroll', windowScroll);

        // // cleanup this component
        // return () => {
        // window.removeEventListener('scroll', windowScroll);
        // };
        // eslint-disable-next-line
    // }, [hideMenu]);
    return (<div id="dashboard_header" className={classNames(styles.headerBar)}>
        {/* , hideMenu ? styles.hideMenu : "" */}
        <div className={ classNames(styles.container)}>
            <div  className={styles.stat}>
                <select name="stat" onChange={(e)=>  {dispatch(statData(e.target.value)); dispatch(onChange("statDate", e.target.value))}}>
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                    <option value="total">Total</option>
                </select>
                {/* <Select name="stat" label="Stat" style={{width:100}} /> */}
                <ul>    
                    <li>
                        <span>IN</span>
                        <b>{stats.in}</b>
                    </li>
                    <li>
                        <span>OUT</span>
                        <b>{stats.out}</b>
                    </li>
                    <li>
                        <span>ASA1</span>
                        <b>{stats.asa1}</b>
                    </li>
                    <li>
                        <span>ASA2</span>
                        <b>{stats.asa2}</b>
                    </li>
                    <li>
                        <span>ASA3</span>
                        <b>{stats.asa3}</b>
                    </li>
                </ul>
                <div className={styles.reload} onClick={(e) => dispatch(statData("reload"))}>
                    <img src={ReloadIcon} alt="" />
                </div>
            </div>
            <div className={styles.headerProfile}>
                <div className={classNames("dropdown", dropdown ? "active" : "")}>
                    <div className={styles.profile} onClick={(e) => setDropdown(!dropdown)}>
                        {auth.userData.nickname}
                        <img src={downWhiteIcon} alt="" />
                    </div>
                    <div className={"dropdown-menu"} style={{top:40}}>
                        <ul>
                            {/* <li>
                                <NavLink className="a"  to={"/users/" + auth.userData.docId}>
                                    <span><FormattedMessage id="Profile.profilOnizleme" /></span>
                                </NavLink>
                            </li> */}
                            <li onClick={e => {setDropdown(false); dispatch(logout())}}>
                                <span className="a">
                                    <FormattedMessage id="Dashboard.cikisYap" />
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className="dropdown-bg" onClick={(e) => setDropdown(false)}></div>
                </div>

            </div>
            {/* <div className={classNames(styles.menu, "d-flex")}>
                <ul className={styles.main}>
                    <li>
                        <NavLink className={styles.a} activeClassName={styles.active} to="/dashboard" exact>
                            <img src={HomeIcon} style={{width:28, height:28}} alt="" />
                            <span><FormattedMessage id="Dashboard.anasayfa" /></span>
                        </NavLink>
                    </li>
                </ul>
                
            </div> */}
        </div>
    
        <Modals />
        <ModalMessage />
    </div>)
}
export default Header_3;
