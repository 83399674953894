
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from "./Dashboard.module.scss"
import { getMessages } from '../../state/actions/messages';
import WaitingList from "../../components/Layout/MessagesList/Waiting"
import AsaList from "../../components/Layout/MessagesList/Asa"
import NewList from "../../components/Layout/MessagesList/New"
import FirstList from "../../components/Layout/MessagesList/First"
import MessageBox from "../../components/Layout/MessageBox/MessageBox_2"
import FakeSide from "../../components/Layout/UserDetail/Fake"
import UserSide from "../../components/Layout/UserDetail/User"
import FriendList from "../../components/Layout/Friends/List"

const Search = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    // if(!twoUsers.length){
      dispatch(getMessages(true));
    // }
    
    // eslint-disable-next-line
  }, [dispatch]);
  return(
    <section className={styles.Dashboard}>
      <div className={styles.list}>
        <b className={styles.title}>Waiting Messages</b>
          <WaitingList />
      </div>
      <div className={styles.list}>
        <b className={styles.title}>New Messages</b>
          <NewList />
      </div>
      <div className={styles.list}>
        <b className={styles.title}>Asa Messages</b>
          <AsaList />
      </div>
      <div className={styles.list}>
        <b className={styles.title}>First Messages</b>
          <FirstList />
      </div>
      <div className={styles.list}>
        <b className={styles.title}>Friendship Requests</b>
          <FriendList />
      </div>
      <div className={styles.message}>
        <UserSide />
        <MessageBox />
        <FakeSide />
      </div>
    </section>
  )
}

export default Search;
