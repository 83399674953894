import { createAction } from 'redux-act';
import firebase from '../../firebase.js';
import firebase_app from 'firebase/compat/app';

export const FAKE_NOTE_DATA = createAction('FAKE_NOTE_DATA');
export const USER_NOTE_DATA = createAction('USER_NOTE_DATA');
export const CALLCENTER_NOTE_DATA = createAction('CALLCENTER_NOTE_DATA');

const fakeData_ = (arr) => {
    let check = true
    for (let index = 1; index < 17; index++) {
        if (document.getElementById("f_n" + index)) {
            document.getElementById("f_n" + index).value = arr["n" + index] ? arr["n" + index] : ""
        } else {
            check = false
        }
    }
    if (!check) {
        setTimeout(doc => {
            fakeData_(arr)
        }, 1000)
    }
}

const userData_ = (arr) => {
    let check = true
    for (let index = 1; index < 17; index++) {
        if (document.getElementById("u_n" + index)) {
            document.getElementById("u_n" + index).value = arr["n" + index] ? arr["n" + index] : ""
        } else {
            check = false
        }
    }
    if (!check) {
        setTimeout(doc => {
            userData_(arr)
        }, 1000)
    }
}


const onChangeNote = (name, value) => {
    return (dispatch, getState) => {
        const messageData = getState().messages.data;
        let noteText = name.split("_")[0] === "f" ? "noteFake" : "noteUser"
        // if(doc){
        value = value.target.value
        firebase.firestore().collection(noteText).where("docId", "==", messageData.docId).limit(1).get().then(doc2 => {
            let be = false
            doc2.forEach(doc2 => {
                be = true
                firebase.firestore().collection(noteText).doc(messageData.docId).update({
                    [name.split("_")[1]]: value
                })
            })
            if (be === false) {
                firebase.firestore().collection(noteText).doc(messageData.docId).set({
                    docId: messageData.docId,
                    timestamp: new Date(),
                    [name.split("_")[1]]: value
                })
            }
        })
        // }
    }
}

const addNote = (type) => {
    return (dispatch, getState) => {
        const userData = getState().auth.userData;
        const messageData = getState().messages.data;

        let note = document.getElementById("noteCallcenter").value
        firebase.firestore().collection("noteCallcenter").add({
            id: messageData.docId,
            note: note,
            time: firebase_app.firestore.FieldValue.serverTimestamp(),
            ccNickname: userData.nickname,
            ccId: userData.docId,
        }).then(doc => {
            firebase.firestore().collection("noteCallcenter").doc(doc.id).update({ i: doc.id })
            document.getElementById("noteCallcenter").value = ""
        })
    }
}

const getNotes = (type) => {
    return (dispatch, getState) => {
        const messageData = getState().messages.data;
        if (!messageData || !messageData.docId) {
            return null
        }
        const unsubscribe = firebase.firestore().collection("noteCallcenter").where("id", "==", messageData.docId).orderBy("time", "desc").onSnapshot(note => {
            const messageData2 = getState().messages.data;
            let arr = []
            note.forEach(doc => {
                if (doc.data().id !== messageData2.docId) {
                    unsubscribe()
                    return null
                }
                if (doc.data().time) { arr.push(doc.data()) }
            })
            if (arr) {
                dispatch(CALLCENTER_NOTE_DATA(arr))
            }
        })
        if (type === "fake") {
            firebase.firestore().collection("noteFake").doc(messageData.docId).get().then(note => {
                if (note.data()) {

                    fakeData_(note.data())
                }
            })
        } else {

            firebase.firestore().collection("noteUser").doc(messageData.docId).get().then(note => {
                if (note.data()) {
                    userData_(note.data())
                }
            })
        }
    };
};


export { onChangeNote, addNote, getNotes };
