import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from '../MessageBox_2.module.scss';
import classnames from 'classnames';
import { imageUploadEnd } from "../../../../state/actions/messages"

import PhotosDarkIcon from "../../../../assets/img/icons/photoDark.svg"

// TimeAgo.addLocale(en)
// const timeAgo = new TimeAgo('en-US')

const Footer = (props) => {
    const dispatch = useDispatch();
    const { fakes } = useSelector(
        (state) => ({
            // messageList: state.messages && state.messages.list ? state.messages.list : [],
            // messages: state.messages,
            // form: state.form,
            // users:state.users.user ? state.users.user : {},
            fakes:state.users.fake ? state.users.fake : {},
            // callcentersName: state.callcenters.name ? state.callcenters.name : {},
            // gifts: state.generalDataReducer.gifts ? state.generalDataReducer.gifts : [],
        }), shallowEqual
    );

    const [ccImage, ccImageSet] = useState(false);
    return (<div style={{overflow:"inherit"}} className={classnames(styles.icon, "dropdown", ccImage ? "active" : "")}>
    <img src={PhotosDarkIcon} style={{width:16, height:16}} alt="" onClick={(e) => ccImageSet(true)} />
    <div className="dropdown-menu left">
        <div className={styles.ccPhoto}>
            {fakes.photos_callcenter && fakes.photos_callcenter.map((post, index) => {
            //  onClick={(e) => this.sendPhoto(post)}
            return <li key={index} onClick={(e) => {dispatch(imageUploadEnd(post));ccImageSet(false)}}>
                <img src={post} alt="" />
            </li>
            })}
            <p>
            <b>profile</b></p>
            {fakes.photos && fakes.photos.map((post, index) => {
            return <li key={index} onClick={(e) => {dispatch(imageUploadEnd(post));ccImageSet(false)}}>
                <img src={post} alt="" />
            </li>
            })}
        </div>
    </div>
    <div className="dropdown-bg" onClick={(e) => ccImageSet(false)}>

    </div>
</div>)
}
export default Footer;
