import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import classnames from 'classnames';
import styles from "./styles.module.scss"
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { getNotes, onChangeNote } from "../../../state/actions/notes"
import {getAge} from "../../../Hooks"
import {useIntl} from 'react-intl';
import {defaultHairColor, defaultEyeColor, defaultBody, defaultStyle, defaultExtra} from "../../../Settings"


TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

const MessageBox = (props) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { userData, messagesData } = useSelector(
        (state) => ({
            userData: state.users.user,
            messagesData: state.messages.data,
        }), shallowEqual
    );

    useEffect(() => {
        dispatch(getNotes("user"))
        // eslint-disable-next-line
    }, [messagesData]);
    let mainuserData = userData;
    let color1 = "#3e606f"
    let color2 = "#c9eeff"
    if(!mainuserData || !mainuserData.meta || !messagesData || !messagesData.docId){
      return null
    }
    return (<div id="user" className={styles.box} style={{backgroundColor:color2}}>
      <div style={{display:"flex"}}>
        <div style={{flex:1, backgroundColor:"#f2f2f2", position:"relative"}}>
          {messagesData.type === "automessage" ? <button style={{backgroundColor:"#2ecc71", color:"#fff", border:0, width:45, height:40, position:"absolute", top:15, left:15, borderRadius:5, fontSize:14, boxShadow:"0 0 5px rgba(0,0,0,0.1)"}}>New</button> : null }
          <div style={{border:0, position:"absolute", top:15, left:15, display:"flex", width:"100%"}}>
          </div>
          <img style={{width:"100%", height:230}} src={mainuserData.meta.avatarUrl} alt="" />
           {/* onClick={(e) => this.props.modalChange(this.state.modal)} */}
          {/* <MdPhotoLibrary size={35} style={{position:"absolute", bottom:12, right:12, color:"#fff", backgroundColor:"#283749", padding:5, borderRadius:5, cursor:"pointer"}} /> */}
        </div>
        <div style={{flex:1, color:color1, backgroundColor:"#f2f2f2"}}>
          <h2 style={{backgroundColor:color1, padding:8, fontSize:14, fontWeight:600, margin:0, color:"#fff", display:"flex", justifyContent:"space-between"}}>{mainuserData.meta.nickname} 
          <div className={styles.close} onClick={(e) => {document.getElementById("user").classList.toggle("show")}}>
            X
          </div>
          {/* <MdFileUpload size={25} style={{cursor:"pointer"}} onClick={this.props.userSendImages} /> */}
          </h2>
          <ul className={styles.ul}>
            {/* <li>
              <b>Ref</b><span>{mainuserData.ref}</span>
            </li> */}
            <li>
              <b>Alter</b><span>{getAge(mainuserData.meta.birtDate)}</span>
            </li>
            <li>
              <b>Ort</b><span>{mainuserData.meta.city}, {mainuserData.meta.country}, {mainuserData.meta.zip}</span>
            </li>
            <li>
              <b>Haarfarbe</b><span>{defaultHairColor[mainuserData.meta.hairColor] ? defaultHairColor[mainuserData.meta.hairColor] : mainuserData.meta.hairColor}</span>
            </li>
            <li>
              <b>Augenfarbe</b><span>{defaultEyeColor[mainuserData.meta.eyeColor] ? defaultEyeColor[mainuserData.meta.eyeColor] : mainuserData.meta.eyeColor}</span>
            </li>
            <li>
              <b>Größe</b><span>{mainuserData.meta.height}m</span>
            </li>
            <li>
              <b>Statur</b><span>{defaultBody[mainuserData.meta.body] ? defaultBody[mainuserData.meta.body] : mainuserData.meta.body}</span>
            </li>
            <li>
              <b>Style</b><span>{defaultStyle[mainuserData.meta.style] ? defaultStyle[mainuserData.meta.style] : mainuserData.meta.style}</span>
            </li>
            <li>
              <b>Tattoo, Piercing</b><span>{defaultExtra[mainuserData.meta.extra] ? defaultExtra[mainuserData.meta.extra] : mainuserData.meta.extra}</span>
            </li>
          </ul>
        </div>
      </div>
      {/* <div>
        <h2 style={{backgroundColor:color1, padding:8, fontSize:14, fontWeight:600, margin:0, color:"#fff"}}>Notizen</h2>
        <textarea style={{width:"100%", height:80, padding:10, backgroundColor:"#f2f2f2", borderWidth:0}}>

        </textarea>
      </div> */}
      <div style={{flexDirection:"row", display:"flex"}}>
        <div style={{flex:1}}>
          <h2 style={{backgroundColor:color1, padding:8, fontSize:14, fontWeight:600, margin:0, color:"#fff"}}>Globale Informationen</h2>
          <ul className={styles.ul_2} style={{color:color1}}>
            <li>
              <p>Kontaktdaten</p><span><input type="text"  id="u_n1" onChange={(e) => dispatch(onChangeNote("u_n1",e))} /></span>
            </li>
            <li>
              <p>Geschwister</p><span><input type="text" id="u_n2"  onChange={(e) => dispatch(onChangeNote("u_n2",e))} /></span>
            </li>
            <li>
              <p>Kinder</p><span><input type="text"  id="u_n3"  onChange={(e) => dispatch(onChangeNote("u_n3",e))} /></span>
            </li>
            <li>
              <p>Haustiere</p><span><input type="text" id="u_n4"  onChange={(e) => dispatch(onChangeNote("u_n4",e))} /></span>
            </li>
            <li>
              <p>Hobbys</p><span><input type="text"   id="u_n5"  onChange={(e) => dispatch(onChangeNote("u_n5",e))} /></span>
            </li>
            <li>
              <p>Sternzeichen</p><span><input type="text" id="u_n6"  onChange={(e) => dispatch(onChangeNote("u_n6",e))} /></span>
            </li>
            <li>
              <p>letzte Beziehung</p><span><input type="text"  id="u_n7"  onChange={(e) => dispatch(onChangeNote("u_n7",e))} /></span>
            </li>
            <li>
              <p>Tattos</p><span><input type="text" id="u_n8"  onChange={(e) => dispatch(onChangeNote("u_n8",e))} /></span>
            </li>
            <li>
              <p>Pircings</p><span><input type="text"  id="u_n9"  onChange={(e) => dispatch(onChangeNote("u_n9",e))} /></span>
            </li>
          </ul>
        </div>
        <div style={{flex:1, color:color1}}>
          <h2 style={{backgroundColor:color1, padding:8, fontSize:14, fontWeight:600, margin:0, color:"#fff"}}>Dialoginfos</h2>
          <ul className={classnames(styles.ul_2, styles.ul_3)} style={{color:color1}}>
            <li>
              <input type="text" placeholder="Name" id="u_n10"  onChange={(e) => dispatch(onChangeNote("u_n10",e))} />
            </li>
            <li>
              <input type="text" placeholder="Ort" id="u_n11"  onChange={(e) => dispatch(onChangeNote("u_n11",e))} />
            </li>
            <li>
              <input type="text" placeholder="Beruf" id="u_n12"  onChange={(e) => dispatch(onChangeNote("u_n12",e))} />
            </li>
            <li>
              <input type="text" placeholder="Auf der suche nach?"  id="u_n13"  onChange={(e) => dispatch(onChangeNote("u_n13",e))} />
            </li>
            <li>
              <input type="text" placeholder="Arbeitszeit" id="u_n14"  onChange={(e) => dispatch(onChangeNote("u_n14",e))} />
            </li>
            <li>
              <textarea style={{height:100, lineHeight: "20px"}} placeholder="Vorlieben / Tabus" id="u_n15"  onChange={(e) => dispatch(onChangeNote("u_n15",e))}></textarea>
            </li>
          </ul>
        </div>
      </div>

      <label style={{color: "#333",fontWeight: 500,marginBottom: 6,display: "block",padding: "0 6px",fontSize: 13}}>Sonstiges</label>
      <textarea style={{flex:1,
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        width: "100%",
        marginBottom: 5,
        lineHeight: "20px",
        textAlign: "left",
        paddingLeft:15,
        paddingRight:15,
        border: 0}} id="u_n16"  onChange={(e) => dispatch(onChangeNote("u_n16",e))}></textarea>
    </div>)
}
export default MessageBox;
