
import { createAction } from 'redux-act';
import firebase from '../../firebase.js';

// const intl = useIntl();

export const ADMIN_MESSAGES = createAction('ADMIN_MESSAGES');
export const WAITING_LIST_DATA = createAction('WAITING_LIST_DATA');
export const NEW_LIST_DATA = createAction('NEW_LIST_DATA');
export const ASA1_LIST_DATA = createAction('ASA1_LIST_DATA');
export const ASA2_LIST_DATA = createAction('ASA2_LIST_DATA');
export const ASA3_LIST_DATA = createAction('ASA3_LIST_DATA');
export const FIRST_LIST_DATA = createAction('FIRST_LIST_DATA');
export const TOTAL_NEW_MESSAGES = createAction('TOTAL_NEW_MESSAGES');
export const MESSAGES_LIST_RESET = createAction('MESSAGES_LIST_RESET');


const messagesListReset = () => {
    return (dispatch) => {
        dispatch(MESSAGES_LIST_RESET())
    }
}

const waitingListData = (limit) => {
    return async (dispatch, getState) => {
        const { ref } = getState().auth.userData;

        firebase.firestore().collection("messagesList").where("callcenterId", "==", 0).where("lastSend", "==", "user").where("asa", "==", false).where("status", "==", true).where("reality", "==", false).where("type", "==", "waiting").onSnapshot(doc => {
            let arr = []
            doc.forEach(doc => {
                if (ref) {
                    if (!ref.includes(doc.data().ref)) {

                        return null
                    }
                }
                let data = doc.data()
                data["docId"] = doc.id
                arr.push(data)
            })
            dispatch(WAITING_LIST_DATA(arr))
        })
    };
};
const newListData = (limit) => {
    return async (dispatch, getState) => {
        const { ref } = getState().auth.userData;
        firebase.firestore().collection("messagesList").where("lastSend", "==", "user").where("asa", "==", false).where("status", "==", true).where("reality", "==", false).where("type", "==", "").orderBy("time", "desc").onSnapshot(doc => {
            let arr = []
            doc.forEach(doc => {
                if (ref) {
                    if (!ref.includes(doc.data().ref)) {

                        return null
                    }
                }
                let data = doc.data()
                data["docId"] = doc.id
                arr.push(data)
            })
            dispatch(NEW_LIST_DATA(arr))
        })
    };
};
const firstListData = (limit) => {
    return async (dispatch, getState) => {
        const { ref } = getState().auth.userData;
        firebase.firestore().collection("messagesList").where("callcenterId", "==", 0).where("lastSend", "==", "user").where("asa", "==", false).where("status", "==", true).where("reality", "==", false).where("type", "==", "automessage").orderBy("time", "desc").limit(100).onSnapshot(doc => {
            let arr = []
            doc.forEach(doc => {
                if (ref) {
                    if (!ref.includes(doc.data().ref)) {

                        return null
                    }
                }
                let data = doc.data()
                data["docId"] = doc.id
                arr.push(data)
            })
            dispatch(FIRST_LIST_DATA(arr))
        })
    };
};


const asaListData = (limit) => {
    return async (dispatch, getState) => {
        const { ref } = getState().auth.userData;

        firebase.firestore().collection("messagesList").where("callcenterId", "==", 0).where("lastSend", "==", "user").where("asa", "==", false).where("status", "==", true).where("reality", "==", false).where("type", "==", "Asa1").orderBy("time", "desc").limit(50).onSnapshot(doc => {
            let arr = []
            doc.forEach(doc => {
                if (ref) {
                    if (!ref.includes(doc.data().ref)) {

                        return null
                    }
                }
                let data = doc.data()
                data["docId"] = doc.id
                arr.push(data)
            })
            dispatch(ASA1_LIST_DATA(arr))
        })
        firebase.firestore().collection("messagesList").where("callcenterId", "==", 0).where("lastSend", "==", "user").where("asa", "==", false).where("status", "==", true).where("reality", "==", false).where("type", "==", "Asa2").orderBy("time", "desc").limit(50).onSnapshot(doc => {
            let arr = []
            doc.forEach(doc => {
                if (ref) {
                    if (!ref.includes(doc.data().ref)) {

                        return null
                    }
                }
                let data = doc.data()
                data["docId"] = doc.id
                arr.push(data)
            })
            dispatch(ASA2_LIST_DATA(arr))
        })
        firebase.firestore().collection("messagesList").where("callcenterId", "==", 0).where("lastSend", "==", "user").where("asa", "==", false).where("status", "==", true).where("reality", "==", false).where("type", "==", "Asa3").orderBy("time", "desc").limit(50).onSnapshot(doc => {
            let arr = []
            doc.forEach(doc => {
                if (ref) {
                    if (!ref.includes(doc.data().ref)) {

                        return null
                    }
                }
                let data = doc.data()
                data["docId"] = doc.id
                arr.push(data)
            })
            dispatch(ASA3_LIST_DATA(arr))
        })
    };
};



export { waitingListData, messagesListReset, newListData, asaListData, firstListData };
