import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from '../MessagesList/Waiting.module.scss';
import { openMessage, closeMessage} from "../../../state/actions/messages"
import { deleteFriend, verifyFriendID} from "../../../state/actions/friends"
import classnames from 'classnames';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')
const MessagesList = (props) => {
    const dispatch = useDispatch();
    const { friends, users } = useSelector(
        (state) => ({
            users: state.users,
            friends: state.friends.list ? state.friends.list : []
        }), shallowEqual
    );
    return (<div className={classnames(styles.general)}>
        <div className={styles.messagesList}>
            <ul id="messageList">
                {friends.map((post, index) => {
                    return (<li id={post.id} key={index}  className={post.callcenterId ? styles.disable : ''}>
                        <div className={styles.content} onClick={(e) => post.callcenterId ? "" : dispatch(verifyFriendID(post.docId))}>
                            <div className={styles.avatarContent}>
                                <div className={styles.avatar} style={{ backgroundImage: "url(" + (users[post.a] && users[post.a].avatar)+ ")" }}></div>
                            </div>
                            <div className={styles.text}>
                                <div className={styles.nickname}>
                                    <b>
                                        {users[post.a] && users[post.a].nickname}
                                    </b>
                                    <small>VS</small>
                                    <b>
                                        {users[post.b] && users[post.b].nickname}
                                    </b>
                                </div>
                            </div>
                            <div className={styles.avatarContent}>
                                <div className={styles.avatar} style={{ backgroundImage: "url(" +(users[post.b] && users[post.b].avatar)+ ")" }}></div>
                            </div>
                        </div>
                        <div className={styles.close} onClick={(e) => dispatch(deleteFriend(post.docId))}>x</div>
                    </li>)
                })}
                
            </ul>
        </div>
    </div>)
}
export default MessagesList;
