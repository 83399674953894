import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from '../MessageBox_2.module.scss';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { defaultFakeId} from "../../../../Settings"
import TickIcon from "../../../../assets/img/icons/tick.svg"
import TickActiveIcon from "../../../../assets/img/icons/tickActive.svg"
import Modal from "../../../Items/modal"
import {FormattedMessage} from 'react-intl';

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

const MessageBox = (props) => {
    const { messageList, gifts, callcentersName } = useSelector(
        (state) => ({
            messageList: state.messages && state.messages.list ? state.messages.list : [],
            callcentersName: state.callcenters.name ? state.callcenters.name : {},
            gifts: state.generalDataReducer.gifts ? state.generalDataReducer.gifts : [],
        }), shallowEqual
    );
    const [modal_Image, modal_ImageStatus] = useState("");

    useEffect(() => {
        if(document.getElementById("lastMessage")){
            let obj = document.getElementById("lastMessage")
            obj.scrollIntoView()
            setTimeout(() => {
                obj.scrollIntoView() 
            }, 500);
            setTimeout(() => {
                obj.scrollIntoView() 
            }, 1000);
        }
        // eslint-disable-next-line
    }, [messageList]);

    return (
            <div className={styles.messageContent} id="message">
                {/* {messageList.length === 50 ? <div style={{ width: "100%", textAlign: "center" }}>
                    <button style={{ border: 0, background: "rgb(243, 168, 86)", margin: "auto", color: "#fff", fontWeight: 600, padding: "8px 12px", borderRadius: 50, fontSize: 12, marginBottom: 20 }}><FormattedMessage id="General.digerMesajlar" /></button>
                </div> : ""} */}
                <ul id="messageListGeneral">
                    {messageList && messageList.length ? messageList.map((post, index) => {
                        if (post.image) {
                            return <li id={messageList.length === (index+1) ? "lastMessage" : ""} key={index} className={post.post.split("_")[0] === defaultFakeId ? styles.messageRight : styles.messageLeft}>
                                {post.type === "gift" ? <div>
                                    <img src={gifts[gifts.findIndex(function(item, i){ return item.id === Number(post.image) })] ? gifts[gifts.findIndex(function(item, i){ return item.id === Number(post.image) })].img : ""} alt="gift" />
                                </div>: 
                                <div>
                                    <p className={post.post.split("_")[0] === defaultFakeId ? styles.post : styles.get}>
                                        <img src={post.image} onClick={(e) => modal_ImageStatus(post.image)} style={{ minWidth: "150px", maxHeight: "350px", cursor: "pointer" }} alt="" />
                                        <br />
                                        {post.message}
                                        <small className={styles.time}>{callcentersName[post.cc]} - {post.time ? timeAgo.format(new Date(post.time.seconds * 1000), 'twitter') : ""}</small>
                                    </p>
                                </div>}
                            </li>
                        }else if(post.type === "gif"){
                            return <li id={messageList.length ===  (index+1) ? "lastMessage" : ""} key={index} className={post.post.split("_")[0] === defaultFakeId ? styles.messageRight : styles.messageLeft}>
                                 <img src={post.message} alt="gif" />
                            </li>
                        }
                        return <li id={messageList.length ===  (index+1) ? "lastMessage" : ""} key={index} className={post.post.split("_")[0] === defaultFakeId ? styles.messageRight : styles.messageLeft}>
                            <div>
                                <p className={post.post.split("_")[0] === defaultFakeId ? styles.post : styles.get}>
                                    {post.message}
                                    <small className={styles.time}>
                                        {callcentersName[post.cc]} - {post.time ? timeAgo.format(new Date(post.time.seconds * 1000), 'twitter') : ""}
                                        {post.visible ?<img src={TickActiveIcon} alt="" /> : <img src={TickIcon} alt="" />}
                                    </small>
                                </p>

                            </div>
                        </li>
                    }) : ""}
                </ul>
            {modal_Image ?<Modal title={<FormattedMessage id="General.fotograf" />} content={<img src={modal_Image} alt="" />} status={modal_Image} width={700} close={(e) => modal_ImageStatus("")} /> : ""}
        </div>)
}
export default MessageBox;
