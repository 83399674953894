
import { createAction } from 'redux-act';
import firebase from '../../firebase.js';


export const CALLCENTER_DATA = createAction('CALLCENTER_DATA');
export const getCallcenters = (size) => {
    return  (dispatch, getState) => {
        firebase.firestore().collection("callcenters").get().then(doc=>{
            let callcenter = []
            let callcenterName = []
            doc.forEach(doc=>{
                callcenter.push(doc.data())
                callcenterName[doc.id] = doc.data().nickname
            })
            dispatch(CALLCENTER_DATA({
                list:callcenter,
                name:callcenterName
            }))
        })
    };
};