import { createReducer } from 'redux-act';

import {
  FAKE_NOTE_DATA,
  USER_NOTE_DATA,
  CALLCENTER_NOTE_DATA
} from '../actions/notes';

import {
  CLEAR_NOTES
} from '../actions/messages';


const initialState = {};

const users = createReducer(
  {
    [FAKE_NOTE_DATA]: (state, payload) => {
      return({
        ...state,
        fake: payload
      })
    },
    [USER_NOTE_DATA]: (state, payload) => {
      return({
        ...state,
        user: payload
      })
    },
    [CLEAR_NOTES]: (state, payload) => {
      return({
        loading:false
      })
    },
    [CALLCENTER_NOTE_DATA]: (state, payload) => {
      return({
        ...state,
        callcenter: payload
      })
    }
  },
  initialState
);
export default users