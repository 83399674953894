
import React from 'react';
import { createAction } from 'redux-act';
import firebase from '../../firebase.js';
import {setUserData} from "./users"
import {userId_2_S} from "../../Settings"
import { error} from './dashboard'
import {firebaseError} from "../../Utils"
import {FormattedMessage} from 'react-intl';
import {getRandomNumber} from "../../Hooks"


export const addFriend = (userId, fakeId) => {
  return (dispatch, getState) => {
    const { docId } = getState().auth.userData;
    firebase.firestore().collection("friends").add({
      a:userId,
      b:fakeId,
      s:false,
      time:new Date(),
      cc:docId
    })
  };
};

export const verifyFriendID = (docId) => {
  return (dispatch, getState) => {
        firebase.firestore().collection("friends").doc(docId).update({
          s:true
        })
  };
};
export const verifyFriend = (userId, fakeId) => {
  return (dispatch, getState) => {
    firebase.firestore().collection("friends").where("a", "==", userId).where("b", "==", fakeId).get().then(doc=>{
      doc.forEach(doc => {
        firebase.firestore().collection("friends").doc(doc.id).update({
          s:true
        })
      });
    })
  };
};

export const deleteFriend = (docId) => {
  return (dispatch, getState) => {
    firebase.firestore().collection("friends").doc(docId).delete()
  };
};

export const FRIENDS_DATA = createAction('FRIENDS_DATA');
export const WAITING_FRIENDS_DATA = createAction('WAITING_FRIENDS_DATA');

    //   firebase.firestore().collection("friends").where("a", "==", W).onSnapshot(doc => {
    //     let arr = []
    //     let arr2 = []
    //     doc.forEach(doc=>{
    //       if(doc.data().s){
    //         arr.push(doc.data().b)
    //       }else{
    //         arr2.push(doc.data().b)
    //       }
    //     })
    //     dispatch(
    //       FRIENDS_DATA({
    //         friends: arr
    //       })
    //     );
    //     dispatch(
    //       WAITING_FRIENDS_DATA({
    //         friends: arr2
    //       })
    //     );
    //   })
export const getFriends = (size) => {
    return (dispatch, getState) => {
        firebase.firestore().collection("friends").where("s", "==", false).where("cc", "==", "").orderBy("time", "desc").limit(10).onSnapshot(doc=>{
            let arr = []
            const users = getState().users;
            doc.forEach(doc=>{
              let data = doc.data()
              data.docId = doc.id
              arr.push(data)
                if(!users[doc.data().a]){
                    firebase.firestore().collection("users").doc(doc.data().a).get().then(doc=>{
                        dispatch(setUserData(doc.data()))
                    })
                }
                if(!users[doc.data().b]){
                    firebase.firestore().collection("users").doc(doc.data().b).get().then(doc=>{
                        dispatch(setUserData(doc.data()))
                    })
                }
            })
            dispatch(
            FRIENDS_DATA({
                friends: arr
            })
            );
        })
    }
}